import domReady from '@wordpress/dom-ready';

function init() {
    const elems = document.querySelectorAll('.circular-progress-tabs-inner');

    function activate( block, item ) {
        const activeItems = block.querySelectorAll('.is-active');
        activeItems.forEach( e => e.classList.remove('is-active') );
        item.classList.add('is-active');
    }

    elems.forEach( ( block ) => {

        const items = block.querySelectorAll('.wp-block-intempus-circular-tab');
        const count = items.length;
        const degreeChunk = 360 / count;

        items.forEach( ( item, index ) => {
            const rotation = degreeChunk * index ;
            item.style.setProperty('--rotation', `${rotation}deg`);
            item.style.setProperty('--rotation-neg', `-${rotation}deg`);

            const button = item.querySelector('.circular-icon');

            if ( ! button ) {
                return;
            }

            activate(block, item);

            button.addEventListener( 'mouseenter', activate.bind( null, block, item ) );

        } )

    })

}

domReady( init );

